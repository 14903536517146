/** AccessNS material theme **/
@import './assets/scss/ns-material.scss';

/** Datetime Picker **/
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

/** Angular Calendar Styles **/
@import "angular-calendar/css/angular-calendar.css";

/** sweet alerts **/
@import './assets/scss/sweetalerts2-ns-theme';

/** bootstrap utils **/
$theme-colors: (
  "primary": $gold,
  "secondary": $darkgrey,
  "success": $success,
  "danger": $danger,
  "danger-dark": $danger-dark,
  "warning": $warning,
  "warning-light": $warning-light,
  "info": $update,
  "light": $light,
  "dark": $black,
  "medium-gray": $medium-gray,
  "light-gold": $light-gold,
  "dark-gold": $dark-gold,
  "medium": $medium,
  "super-light": $super-light,
  "beige": $beige,
  "travertine": $travertine,
  "lavender": $lavender,
  "teal": $teal,
  "light-green": $light-green,
  "astronaut-blue": $astronaut-blue
);
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/grid";
@import 'bootstrap/scss/utilities';

/** NG Toastr **/
@import 'ngx-toastr/toastr';

/** icons **/
@import '@fortawesome/fontawesome-free/css/all.min.css';

/** main variables **/
@import './assets/scss/colors';
@import './assets/scss/fonts';

/** custom ng-material **/
@import './assets/scss/ns-overrides.scss';

/** custom pdf **/
@import './assets/scss/ns-pdf.scss';

/** custom tippy **/
@import './assets/scss/tippy.scss';

/** AG Grid Theme **/
@import "assets/scss/ag-style";

/** Tippy **/
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light-border.css';
@import 'tippy.js/themes/light.css';

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  font-family: $font-family-primary, $font-family-secondary;
  font-size: 14px;
  background-color: #343a40;
}


/* scroll */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: $super-light;
}

*::-webkit-scrollbar-thumb {
  background-color: $medium-gray;
  outline: 1px solid $medium;
}

* {
  scrollbar-color: $medium $super-light;
  scrollbar-width: thin;
}

.filler {
  flex: 1 1 auto;
}

.font-inherit {
  font-size: inherit;
}

.dashboard-dialog-content {
  font-weight: 600;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.multiline-tooltip {
  white-space: pre-line;
}

.border {
  &-1 {
    border-width: 1px !important;
  }

  &-2 {
    border-width: 2px !important;
  }

  &-3 {
    border-width: 3px !important;
  }

  &-4 {
    border-width: 4px !important;
  }

  &-5 {
    border-width: 5px !important;
  }
}

.mat-tooltip-lg {
  font-size: 12px !important;
}


/* NS Embedded Chat */
.embeddedServiceHelpButton .helpButton .uiButton {
  font-family: $font-family-primary, $font-family-secondary;
}

.embeddedServiceHelpButton .helpButton {
  right: 20px !important;
  bottom: 30px !important;

  button.helpButtonEnabled {
    width: 50px !important;
    height: 50px !important;
    min-width: unset;
    border-radius: 50% !important;
    font-size: 1.8em !important;
    padding: 0 9px !important;

    .embeddedServiceIcon {
      display: block !important;
    }

    .helpButtonLabel {
      display: none;
    }

    &:focus::before, &:hover::before  {
      border-radius: 50% !important;
    }
  }
}

button.embeddedServiceSidebarMinimizedDefaultUI {
  width: 50px !important;
  height: 50px !important;
  min-width: unset;
  border-radius: 50% !important;
  font-size: 1.8em !important;
  padding: 0 9px !important;

  right: 20px !important;
  bottom: 30px !important;
}

.embeddedServiceSidebar {
  .uiButton, .embeddedServiceSidebarButton {
    font-family: $font-family-primary, $font-family-secondary;

    &:hover, &:focus {
      background-color: $dark-gold !important;
    }
  }

  &.modalContainer {
    z-index: 30000 !important;
    font-family: $font-family-primary, $font-family-secondary;

    .sidebarHeader img[alt="logo"] {
      display: none;
    }
  }
}

button.minimizedContainer {
  .minimizedImage {
    margin-right: 0 !important;

    .uiImage {
      img {
        vertical-align: unset !important;
      }
    }

  }

  .minimizedText {
    display: none;
  }

  &.newMessage {
    background-color: #1a1b1e !important;

    .minimizedText {
      display: block;
    }

    .content {
      font-size: 16px !important;
      padding: 0px !important;
    }
  }
}

.footer-menu-items a {
  color: $darkgrey !important;
}

.initial-online-eta-header:hover mat-icon {
  color: $gold;
}
