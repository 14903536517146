@import '@fortawesome/fontawesome-free/css/all.min.css';
@import "colors";

@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";

.ag-theme-material {
  @include ag-theme-material((
    material-primary-color: $gold,
    odd-row-background-color: $super-light,
    row-hover-color: $light-gold,
    selected-row-background-color: $light-gold,
    material-accent-color: $gold,
    font-family: ("Lato-Regular", sans-serif),
    font-size: 14px
  ));

  .ag-status-bar {
    max-height: 45px;
    align-items: center;
  }

  & .center-align {
    .ag-header-group-cell-label, .ag-header-cell-label {
      justify-content: center;
    }
  }

  & .right-align {
    .ag-header-group-cell-label, .ag-header-cell-label {
      justify-content: flex-end;
      margin-right: 6px;
    }
  }

  & .break-text {
    .ag-header-cell-text {
      white-space: pre-wrap;
      text-align: center;
    }
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding-left: 15px;
    padding-right: 15px;
  }

  .ag-header-group-separator {
    border-right: 1px solid #e2e2e2;
  }

  .ag-header-group-font-size {
    font-size: 15px !important;
  }

  .ag-row-group {
    align-items: center;
  }

  & .ag {
    &-cell {
      padding-left: 15px;
      padding-right: 15px;
      line-height: normal;
      justify-content: left;
      display: flex;
      align-items: center;

      &-separator {
        border-right: 1px solid #e2e2e2 !important;
      }
    }

    &-header-row {
      color: $black;
      font-size: 12px;
    }

    &-icon-menu {
      font-family: "Font Awesome 5 Free", sans-serif;
      font-weight: bold;
    }

    &-icon-menu::before {
      content: '\f107';
    }
  }

  & .dispute-header {
    color: $white;
    background: $black;
  }

  & .ag-header {
    border-top: 1px solid #e2e2e2;
  }
}
