@import 'src/assets/scss/colors';

.tippy-box[data-theme~=ns] {
  background-color: $white;
  border: 3px solid $black;
  color: $darkgrey;
  box-shadow: 3px 5px 8px 0 rgb(0 0 0 / 40%);
  font-family: Lato-Regular, sans-serif;
  font-size: 16px;
  border-radius: 0;

  .tippy-content {
    padding: 10px 15px;
  }
}
