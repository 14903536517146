@import '@angular/material/theming';

// Primary
$gold: #F4BA05;
$darkgrey: #2C2D28;

// Secondary
$black: #1D1D1D;
$medium-gray: #555650;
$light-gold: #FAE39B;
$dark-gold: #C39404;
$teal: #027B74;
$light-green: #98dc5b;
$dark-green: #2c4516;

// Supporting
$medium: #969696;
$light: #C8C8C9;
$super-light: #EFEFEF;
$beige: #F7F7F3;
$dark-beige: #E0E0E0;
$grey: #DEE2E6;
$white: #FFFFFF;
$travertine: #E2DDC7;
$lavender: #BDCEFA;
$astronaut-blue: #034764;

// Status
$danger-dark: #7a0000;
$danger: #e30202;
$update: #0F4749;
$warning: #E07600;
$warning-light: #fb6901;
$success: #4F772C;

// Charts
$chart-red: #F26C54;
$chart-light-red: #FE9090;
$chart-light-blue: #8DDDD0;
$chart-blue: #29B7DE;
$chart-light-purple: #D5A3E9;
$chart-purple: #D95CFC;
$chart-yellow: #FFC656;
$chart-green: #9DD866;
$chart-light-green: #C9E7AC;
$chart-lighter-blue: #8FD7E8;

$ns-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: $danger,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$ns-gold: (
  50: #fff8e1,
  100: #ffecb3,
  200: #ffe082,
  300: #ffd54f,
  400: #ffca28,
  500: #ffc107,
  600: #ffb300,
  700: $gold,
  800: #ff8f00,
  900: #ff6f00,
  A100: #ffe57f,
  A200: #ffd740,
  A400: #ffc400,
  A700: #ffab00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$ns-grey: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: $dark-beige,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: $darkgrey,
  A100: $white,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);
