@import "src/assets/scss/colors";

$lato-font: 16px Lato-Regular, sans-serif;

@mixin base {
  font: $lato-font !important;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);

  .logo {
    height: 20px;
    width: 175px;
    filter: invert(1);
  }

  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  p {
    margin: 0;
  }

  .container {
    max-width: 100% !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  h4 {
    margin-bottom: 0;
  }

  table {
    font: $lato-font !important;
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding: 0;
    text-align: left;
    border-bottom: 1px solid $super-light !important;
  }

  tr {
    background: transparent;
  }

  tr.mat-header-row {
    height: 26px;
  }

  tr.mat-row,
  tr.mat-footer-row {
    height: 28px;
  }

  .mat-header-cell {
    color: $light;
    font-size: 0.67em;
  }

  .mat-cell {
    font-size: 0.83em;
  }

  .mat-cell,
  .mat-header-cell {
    padding: 0 .5rem;
    font-weight: bold;
  }

  .mat-flat-button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    overflow: visible;
    border-radius: 0 !important;
    box-shadow: none;
  }
}

@media print {
  html,
  body {
    background: $white !important;
  }

  body[print-generator] {
    @include base;
  }

  body[diversions-submit-details] {
    @include base;

    .overflow-auto {
      overflow: hidden !important;
    }

    ns-grid {
      height: 100% !important;
      width: 700px;

      div {
        #grid-container {
          ag-grid-angular {
            div {
              div[ref~="rootWrapperBody"] {
                height: 100% !important;
              }
            }
          }
        }
      }
    }

    .h-100 {
      height: auto !important;
    }

    .mat-ripple-element {
      display: none;
    }
  }

  body[ut-calendar] {
    @import 'src/assets/scss/colors';
    @import 'src/assets/scss/ut-colors';
    @include base;

    .cal-month-view {
      .cal-day-cell.cal-today {
        background-color: #fdf8e8;

        .cal-day-number {
          font-size: 14px;
        }
      }

      .cal-cell-top {
        min-height: auto;

        .cal-day-number {
          font-size: 14px;
          margin: 5px 5px 5px;
          color: $black;
          font-weight: 700;
        }
      }

      .cal-cell-day-muted {
        background-color: #f9f7f7;

        .cal-day-number {
          opacity: 0.2;
          color: #000000 !important;
        }
      }
    }

    .cal-ut-event {
      font-size: 12px;
      border: 1px solid #c1c0c0;
      border-radius: 5px;
      padding: 2px 4px;
      cursor: pointer;

      .foreign-flag {
        display: inline-block;
        color: $white;
        background-color: #194479;
        border-radius: 50%;
        width: 11px;
        height: 11px;
        font-size: 9.5px;
        text-align: center;
      }
    }

    .mat-checkbox {
      font-size: 14px;
    }

    .mat-checkbox-inner-container {
      display: inline-block;
      height: 16px;
      line-height: 0;
      margin: auto;
      margin-right: 8px;
      order: 0;
      position: relative;
      vertical-align: middle;
      white-space: nowrap;
      width: 16px;
      flex-shrink: 0;
    }

    .mp-check-filter {
      background-color: $light;
      color: $white;
      font-weight: 600;
      font-size: 12px;
      padding: 1px 2px;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 1;

      &.type-requested {
        background-color: $ut-status-requested;
      }

      &.type-waybilled {
        background-color: $ut-status-waybilled;
      }

      &.type-arrived {
        background-color: $ut-status-arrived;
      }

      &.type-closed {
        background-color: $ut-status-closed;
      }

      &.type-scheduled {
        background-color: $ut-status-scheduled;
      }

      &.type-moving {
        background-color: $ut-status-moving;
      }

      &.type-released {
        background-color: $ut-status-released;
      }

      &.type-cancelled {
        background-color: $ut-status-cancelled;
      }

      &.type-partially-permitted {
        background-color: $ut-status-moving;
      }

      &.type-loading-commenced {
        background-color: #612405;
      }

      &.type-nominated {
        background-color: #04197c;
      }

      &.type-fully-permitted {
        background-color: #1a863a;
      }

      &.type-loading-completed {
        background-color: $ut-status-closed;
      }
    }

    .exclude-export {
      display: none;
    }
  }

  body[permit] {
    @include base;

    .mat-form-field-suffix button {
      display: none;
    }

    .mat-form-field-label-wrapper {
      label:not(.mat-form-field-empty) {
        left: -24px;
        position: absolute;
      }

      .mat-form-field-empty {
        margin-top: -18px;
      }
    }

    input {
      width: 100%;
    }

    ns-select {
      .ns-select-value {
        margin: -18px 8px !important;
      }
    }

    .mat-radio-container {
      display: inline-block;
      position: relative;
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }

    .mat-radio-outer-circle {
      height: 20px;
      position: absolute;
      width: 20px;
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;
    }

    .mat-radio-inner-circle {
      border-radius: 50%;
      height: 20px;
      position: absolute;
      width: 20px;
      transform: scale(0.001);
    }

    .mat-radio-checked .mat-radio-inner-circle {
      transform: scale(0.5);
    }
  }

  .ecr-status {
    mat-card {
      height: 100%;
      box-sizing: border-box;
      background-color: transparent;
      border: 0;
      text-align: center;
      box-shadow: none !important;

      .header {
        font-size: 12px;
        height: 40px;
        padding: 15px 10px;
      }

      #card-content {
        text-align: center;
        font-size: 16px;
      }
    }

    &-header-font {
      color: black !important;
    }

    &-list {
      display: flex;
      margin-top: 25px;
      margin-bottom: 15px;
      height: 90px;

      mat-list {
        padding: 0;
        margin: 0;

        &--item {
          font-size: 10px;
        }

        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }

    &-customerName {
      margin-left: 25px;
    }

    &-align {
      display: flex;
      justify-content: center;
    }

    &-subheader {
      font-size: 12px;
    }

    &-color {
      color: $black !important;
    }

    &-hide {
      display: none;
    }

    &-w40 {
      width: 40%;
    }

    &-w50 {
      width: 50%;
    }

    &-w60 {
      width: 60%;
    }
  }
}
