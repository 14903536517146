@import 'sweetalert2/src/variables';
@import './colors';

// CONFIRM BUTTON
$swal2-confirm-button-border-radius: .25em !important;
$swal2-confirm-button-background-color: $gold !important;
$swal2-confirm-button-color: $white !important;
$swal2-confirm-button-focus-box-shadow: 0 0 0 3px rgba(212, 212, 212, 0.5) !important;

// CANCEL BUTTON
$swal2-cancel-button-border: 1px solid $light !important;
$swal2-cancel-button-border-radius: .25em !important;
$swal2-cancel-button-background-color: $danger-dark !important;
$swal2-cancel-button-color: $white !important;
$swal2-cancel-button-focus-box-shadow: 0 0 0 3px rgba(212, 212, 212, 0.5) !important;

// DENY BUTTON
$swal2-deny-button-background-color: $danger-dark !important;

// COMMON VARIABLES FOR CONFIRM AND CANCEL BUTTONS
$swal2-button-focus-box-shadow: 0 0 0 1px $swal2-background, 0 0 0 2px $light-gold;

// Loading Icon
$swal2-loader-border-color: $gold transparent $gold transparent !important;

@import 'sweetalert2/src/sweetalert2';
